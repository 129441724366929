.questions {
  .table {
    margin: 0;
    font-size: 16px;
    color: $body-color;

    @include media-breakpoint-up(lg) {
      font-size: 17px;
    }

    thead th {
      border: none;
      vertical-align: middle;
      font-size: 15px;
      font-weight: normal;
      letter-spacing: 0.75px;
      color: $gray;
    }

    th,
    td {
      // padding-left: 0;
      padding-right: 0;
      border-color: $border-color;
    }

    tbody {
      tr:first-child {
        td {
          border-top: none;
        }
      }
    }
  }

  .questions-polls,
  .questions-overall {
    width: 80%;

    @include media-breakpoint-up(md) {
      width: auto;
    }
  }

  .questions-polls {
    padding-left: 20px;
  }

  .questions-quantity {
    padding-right: 20px;
    @include media-breakpoint-up(md) {
      width: 20%;
    }
  }

  .questions-percentage {
    display: none;

    @include media-breakpoint-up(md) {
      display: table-cell;
    }

    .progress-questions {
      display: inline-block;
    }
  }
}
