.carousel-control-prev,
.carousel-control-next {
  background-color: $blue;
  top: 120px;
  width: 25px;
  height: fit-content;
  opacity: 1;
  border-radius: 40px 0 0 40px;
  justify-content: end;
}

.carousel-control-prev {
  border-radius: 0 40px 40px 0;
  justify-content: start;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 25px;
}
